import React from "react";
import styled from "styled-components";

export default class Icon extends React.Component {
 render() {
  return (
   <svg class="illustration" width="1152" height="700" viewBox="0 0 1152 700" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M441.294 281.614C563.651 197.64 745.204 218.86 820.686 239.967C910.318 265.031 914.578 340.259 704.277 501.321C493.976 662.383 181.286 542.968 180.011 480.498C178.736 418.028 288.348 386.58 441.294 281.614Z" fill="#ECE6E6"/>
<path d="M219.828 440.592L175 454.776L289.871 507L384.195 470.895L463.578 500.553L500 485.079L428.089 457.355L452.371 445.75L415.014 430.921L384.195 440.592L300.144 409L260.92 421.895L342.17 454.776L289.871 474.118L219.828 440.592Z" fill="#EE7E79"/>
<path d="M683.828 247.592L639 261.776L753.871 314L848.195 277.895L927.578 307.553L964 292.079L892.089 264.355L916.371 252.75L879.014 237.921L848.195 247.592L764.144 216L724.92 228.895L806.17 261.776L753.871 281.118L683.828 247.592Z" fill="#EE7E79"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M724.92 228.895L806.17 261.776L790 267.756L724.92 241V228.895ZM927.578 307.552L964 292.079V304.5L927.578 322L848.195 292.079L753.871 331L639 277.895V261.776L753.871 314L848.195 277.895L927.578 307.552ZM260.92 421.895L342.17 454.776L326 460.756L260.92 434V421.895ZM463.578 500.552L500 485.079V497.5L463.578 515L384.195 485.079L289.871 524L175 470.895V454.776L289.871 507L384.195 470.895L463.578 500.552ZM564.089 435L357 368.288V384L564.089 453.5L758 390V373.712L564.089 435Z" fill="#f17e57"/>
<path d="M564.089 435L357 368.288L564.089 307L758 373.712L564.089 435Z" fill="#EE7E79"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M491.407 484.313L441.818 143.059L453.472 143.973L458.606 182.614L459.648 189.946L460.332 194.75L540.313 216.334L522.071 130.341L533.227 131.361L604.13 465.611L592.604 462.847L579.862 402.776L489.207 397.783L501.936 487.289L491.407 484.313ZM557.761 298.588L542.903 228.548L461.878 205.623L472.594 280.97L557.761 298.588ZM473.996 290.831L559.929 308.807L577.905 393.554L487.696 387.163L473.996 290.831Z" fill="#DAE4FA"/>
<path d="M496.208 257.336L503.392 255.768C500.664 253.934 501.208 250.594 501.821 249.153L500.925 245.047L493.512 246.729L494.637 250.721C496.547 251.822 496.48 255.59 496.208 257.336Z" fill="#4B5361"/>
<path d="M505.072 263.75L503.393 255.767L496.208 257.336C495.925 261.931 499.026 271.864 513.692 274.835L514.609 273.243C511.536 272.396 505.325 269.312 505.072 263.75Z" fill="#DAE4FA"/>
<path d="M510.551 261.036C509.645 259.665 509.425 257.423 509.429 256.473C507.946 256.734 504.664 256.957 503.392 255.767L505.072 263.75C505.872 263.069 508.088 261.573 510.551 261.036Z" fill="#CDD6E6"/>
<path d="M509.429 256.473L531.906 251.878C531.673 253.161 532.354 254.798 533.132 256.105L510.552 261.036C509.645 259.665 509.425 257.423 509.429 256.473Z" fill="#4B5361"/>
<path d="M572.013 249.639C573.734 246.227 571.129 242.115 570.22 241.428L532.468 250.747C532.16 251.069 531.983 251.454 531.906 251.878C531.672 253.161 532.353 254.798 533.131 256.105C533.476 256.685 533.84 257.2 534.152 257.59L572.013 249.639Z" fill="#EE7E79"/>
<path d="M634.084 259.609C619.306 251.455 604.018 261.138 603.253 261.393C598.667 264.196 598.157 269.801 596.374 271.584C595.568 272.39 590.004 275.916 588.984 276.68C587.965 277.445 588.475 278.464 589.239 278.974C590.004 279.483 589.749 279.738 588.984 281.267C588.373 282.49 588.73 284.155 588.984 284.834C586.946 284.834 587.456 286.618 587.456 287.382C587.456 288.146 584.908 289.93 583.379 292.988C581.85 296.045 592.806 300.886 595.864 304.708C598.31 307.766 617.947 305.982 627.459 304.708C628.224 303.01 629.804 299.001 630.007 296.555C630.262 293.497 637.397 291.204 640.199 288.146C643.002 285.089 648.863 267.763 634.084 259.609Z" fill="#EDC7C5"/>
<path d="M599.339 310.765C597.708 311.173 583.201 309.916 579.21 314.332C572.585 308.726 562.393 265.156 561.119 264.391C560.147 263.808 558.943 260.552 558.392 258.779C558.367 258.781 558.342 258.783 558.316 258.786C555.513 259.04 550.672 248.084 548.379 246.3C546.085 244.517 544.811 239.93 544.811 238.656C544.811 237.382 546.34 235.598 548.888 238.656C551.436 241.714 552.71 237.637 557.551 238.656C562.393 239.675 565.195 249.867 566.724 252.925C568.253 255.983 601.377 310.255 599.339 310.765Z" fill="#EDC7C5"/>
<path d="M534.11 370.388C532.275 368.758 538.441 353.232 541.754 345.673C535.554 344.399 521.064 341.851 512.707 341.851C502.26 341.851 490.029 330.13 490.029 328.856C490.029 327.582 488.501 320.447 487.736 317.899C486.972 315.351 488.501 307.452 483.914 308.217C479.328 308.981 482.385 320.957 480.856 321.976C479.328 322.995 473.467 319.938 469.9 320.957C466.333 321.976 467.607 323.76 467.097 325.289C464.549 327.072 467.862 330.13 465.823 331.659C464.193 332.882 464.634 335.056 465.059 335.99C464.634 337.009 464.04 339.608 465.059 341.851C466.333 344.653 470.664 342.36 473.467 344.908C476.27 347.456 481.621 346.182 485.443 348.73C489.01 355.355 536.403 372.427 534.11 370.388Z" fill="#EDC7C5"/>
<path d="M620.997 301.338C632.972 301.083 627.112 304.905 630.17 308.218C633.227 311.53 645.458 322.996 648.006 332.424C650.554 341.851 662.784 379.816 662.784 383.129C662.784 386.441 665.842 438.675 664.313 439.695C662.784 440.714 588.637 441.988 586.853 439.695C585.07 437.401 584.305 422.368 582.012 416.763C579.719 411.157 570.037 374.211 571.311 364.783C562.223 369.115 542.009 377.116 533.855 374.466C525.701 371.816 525.362 371.153 526.211 371.153C526.296 362.66 527.944 345.164 533.855 343.125C541.244 340.577 538.187 346.438 549.653 343.125C561.119 339.813 566.469 332.678 570.546 331.15C574.623 329.621 575.387 317.645 576.661 314.588C577.477 312.141 577.851 311.53 577.935 311.53C578.183 311.451 578.438 311.37 578.7 311.287C581.469 310.411 585.012 309.335 588.905 308.218C589.756 305.148 587.405 292.213 588.905 296.213C590.105 299.413 606.221 299.429 614.554 302.037C617.047 301.628 619.247 301.375 620.997 301.338Z" fill="url(#paint0_linear)"/>
<path d="M581.758 359.432C576.866 361.879 571.566 365.038 569.527 366.312C569.527 369.624 575.897 409.373 579.719 415.998C583.541 422.623 584.051 434.598 584.306 435.872C584.561 437.146 590.421 436.382 592.459 436.382C603.161 436.892 662.784 446.064 665.587 439.694C668.39 433.324 665.078 383.383 664.058 378.797C663.039 374.211 652.338 336.245 649.79 330.385C647.242 324.525 641.083 308.092 630.126 302.996C619.17 297.9 608.214 300.703 613.055 301.212C617.896 301.722 623.545 314.078 632.973 318.155C651.064 334.207 642.146 351.788 636.285 360.961C630.425 370.134 615.901 378.033 600.103 375.485C587.465 373.446 582.607 363.934 581.758 359.432Z" fill="#22a6b3"/>
<path d="M605.454 265.173C603.619 265.173 596.366 259.227 592.969 256.255C592.969 254.828 598.744 256 601.632 256.764C602.227 256.255 603.67 255.236 604.69 255.236C605.964 255.236 605.454 253.707 613.862 248.611C622.271 243.515 635.52 244.279 646.732 256.255C657.943 268.23 650.044 283.518 648.77 285.302C647.496 287.085 647.496 287.595 647.751 289.379C648.006 291.162 645.458 290.907 646.732 292.691C648.006 294.475 647.751 295.494 645.458 295.494C643.623 295.494 628.556 282.584 621.252 276.129C615.391 260.077 607.747 265.173 605.454 265.173Z" fill="#FFCB56"/>
<path d="M358.768 331.84C360.483 345.889 361.818 356.42 375.909 363.946C373.553 351.31 369.637 331.849 365.522 314.383C370.547 299.249 380.145 267.463 378.34 261.396C375.539 269.939 367.83 287.803 359.407 290.905C357.357 284.048 355.434 278.884 353.824 276.622C352.15 278.832 350.063 286.346 352.603 300.383C347.483 311.556 339.108 336.138 346.568 345.075L344.588 347.26C340.156 332.294 334.957 295.426 349.621 267.682C343.642 271.117 331.451 282.831 330.518 302.197C329.585 321.563 333.908 336.853 336.186 342.077C331.687 334.193 322.709 313.76 322.786 295.104C322.882 271.783 321.855 263.717 352.069 228.625C339.093 232.286 312.779 244.123 311.336 262.184C309.532 284.762 315.619 314.838 329.013 336.414C311.27 312.775 270.889 265.293 251.3 264.483C252.621 267.26 258.515 276.056 271.524 289.034C284.532 302.012 306.662 320.926 316.101 328.761C305.433 321.406 280.838 306.701 267.806 306.715C254.774 306.729 207.666 306.938 185.741 307.04C200.166 314.337 236.81 328.567 267.982 327.107C299.155 325.648 311.526 333.371 313.816 337.415C289.979 341.087 238.162 351.604 221.581 364.293C241.16 361.815 287.251 354.289 314.979 344.016C319.031 342.903 328.693 341.76 334.923 346.099C317.746 349.284 280.634 359.839 269.601 376.569C283.807 372.428 314.656 362.769 324.408 357.264C336.598 350.383 352.137 352.07 349.918 353.643C347.698 355.216 301.051 366.393 277.322 389.073C299.14 384.779 345.092 373.592 354.36 363.196C355.111 361.491 357.76 358.48 362.35 360.073C366.941 361.665 360.3 356.641 356.406 353.93C355.945 347.289 355.771 333.576 358.768 331.84Z" fill="#B7CFFD"/>
<defs>
<linearGradient id="paint0_linear" x1="595.317" y1="295.452" x2="595.317" y2="440.98" gradientUnits="userSpaceOnUse">
<stop stop-color="#DAE4FA"/>
<stop offset="1" stop-color="white"/>
</linearGradient>
</defs>
</svg>  );
 }
}
