
import React from 'react'
import '../../layout/sectiontop.css';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import appstoredownload from "../../images/appstoredownload.svg";
import appstoredownloadmac from "../../images/appstoredownloadmac.svg";


const Section = () => {
return (
<section className="section-top">
       <Zoom>
       <div className="cover-hero">
        <Fade cascade>
        <div className="cover-text">
            <h1>Streamline your renting journey</h1>
            <br/>
            <p>Helping both landlords and tenants manage their properties, <br />from pre to post tenancy by providing a platform for all your rental needs.</p>
              <a href="https://apps.apple.com/app/maison-property-manager/id1551274067">
             
              <div className='actions'>
                <div class="appoftheday"></div>
                <div className="download-button">
                  <p>Download</p>
                </div>
              </div>
             </a> 
        </div>
        </Fade>
      </div>
       </Zoom>
      <div className="bottom-hero">
          <h5>Loved by landlords  and tenants around the world</h5>
          <div className="reviewhighlight">
            <div class="reviewstars"> 
                <p>Ratings</p>
                <div class="stars">&nbsp;</div>
            </div>
          <p>2500+ Homes</p>
          <p>2000+ Renters</p>
          <p>$2.2m+ Expenses</p>
          </div>
          <div className="card-wrapper">
                <div className="card-tenant">
                    <h1>Tenants, simplify your <br/>renting journey</h1>
                    <p>Giving tenants more control than ever before. Create a renters pass. Manage your home with housemates.</p>
                </div>

                <div className="card-landlord">
                    <h1>Landlords, boost your <br/>rental success</h1>
                    <p>A foundation for your property rentals. Market your rentals. Process applications. Manage properties.</p>
                </div>
          </div>
      </div>

</section>
)}

export default Section
