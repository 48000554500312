import React from 'react'
import '../../layout/comingsoon.css';
import '../../layout/homepage.css';
import Zoom from 'react-reveal/Zoom';
import Subscribe from './subscribe-form.js'
import Fade from 'react-reveal/Fade';

const ComingSoon = () => {
  return (
    <div className="coming-Soon" id="copilot">
        <div className="comingSoon-Feature">
          <div className="coming-soon-text">
            <h1>Renting reAImagined</h1>
            <p>An entirely new rental experience, helping renters to navigate the rental market, draft documents, resolve property issues and more.</p>
            <br/>
          </div>
          <Zoom>
          <div className="comingSoon-img">  </div>
          </Zoom>
        </div>

        <div className="section-wrapper" id="requestinvite">
          <div className="renterspass-hero">
            <Fade left cascade>
            <div className="renterspass-heading">
            <h2>Available now for iOS, Mac & Vision Pro</h2>

            <div className="downloadButton">
              <a target="_blank" href="https://apps.apple.com/gb/app/aison-property-management/id1551274067">
                  <p className="buttonText">Download</p>
              </a>
            </div>
            
            </div>
            </Fade>
            <Zoom>
          <div className="apppreview-hero-img">
          </div>
            </Zoom>
            </div>
      </div>

      <div className="get-early-access" >
        <h2>Keep posted on what <br/> we're building</h2>
        <Subscribe/>
      </div>
      
      
    </div>

  )
}

export default ComingSoon
