import React from 'react';
import '../layout/App.css';
import '../layout/legal.css';
import Footer from "../components/homepage/footer.js";

function Policy() {
  return (
    <div>
    <div className="Legal-Content-Wrapper">

      <h1>Privacy Policy</h1>
      <p>Your privacy is important to us. It is Maison Software Limited's policy to respect your privacy regarding any information we may collect from you across our website and mobile app, <a href="http://maison.app">http://maison.app</a>, and other sites or apps we own and operate.</p>
      <h3>1. Information we collect</h3>
      <h4>Log data</h4>
      <p>When you visit our website or app, our servers may automatically log the standard data provided by your web browser. It may include your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.</p>
      <h4>Device data</h4>
      <p>We may also collect data about the device you’re using to access our website. This data may include the device type, operating system, unique device identifiers, device settings, and geo-location data. What we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.</p>
      <h4>Personal information</h4>
      <p>We may ask for personal information, such as your:</p>
      <ul>
         <li>Name</li>
         <li>Email</li>
         <li>Date of birth</li>
         <li>Phone/mobile number</li>
         <li>Home/Mailing address</li>
         <li>Work address</li>
         <li>Payment information</li>
         <li>Employment Information</li>
      </ul>
      <h3>2. Legal bases for processing</h3>
      <p>We will process your personal information lawfully, fairly and in a transparent manner. We collect and process information about you only where we have legal bases for doing so.</p>
      <p>These legal bases depend on the services you use and how you use them, meaning we collect and use your information only where:</p>
      <ul>
         <li>it’s necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract (for example, when we provide a service you request from us);</li>
         <li>it satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote our services, and to protect our legal rights and interests;</li>
         <li>you give us consent to do so for a specific purpose (for example, you might consent to us sending you our newsletter); or</li>
         <li>we need to process your data to comply with a legal obligation.</li>
      </ul>
      <p>Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time (but this will not affect any processing that has already taken place).</p>
      <p>We don’t keep personal information for longer than is necessary. While we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain your personal information for our compliance with a legal obligation or in order to protect your vital interests or the vital interests of another natural person.</p>
      <h3>3. Collection and use of information</h3>
      <p>We may collect, hold, use and disclose information for the following purposes and personal information will not be further processed in a manner that is incompatible with these purposes:</p>
      <ul>
         <li>to enable you to customize or personalize your experience of our website;</li>
         <li>to enable you to access and use our website, associated applications and associated social media platforms;</li>
         <li>to contact and communicate with you;</li>
         <li>for internal record keeping and administrative purposes;</li>
         <li>for analytics, market research and business development, including to operate and improve our website, associated applications and associated social media platforms;</li>
         <li>to run competitions and/or offer additional benefits to you;</li>
         <li>for advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you;</li>
         <li>to comply with our legal obligations and resolve any disputes that we may have; and</li>
         <li>to consider your employment application.</li>
      </ul>
      <h3>4. Disclosure of personal information to third parties</h3>
      <p>We may disclose personal information to:</p>
      <ul>
         <li>third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, hosting and server providers, ad networks, analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;</li>
         <li>our employees, contractors and/or related entities;</li>
         <li>sponsors or promoters of any competition we run;</li>
         <li>credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you;</li>
         <li>courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights;</li>
         <li>third parties, including agents or sub-contractors, who assist us in providing information, products, services or direct marketing to you; and</li>
         <li>third parties to collect and process data.</li>
      </ul>
      <h3>5. International transfers of personal information</h3>
      <p>The personal information we collect is stored and processed in United Kingdom, United States and Ireland, or where we or our partners, affiliates and third-party providers maintain facilities. By providing us with your personal information, you consent to the disclosure to these overseas third parties.</p>
      <p>We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or the use of binding corporate rules or other legally accepted means.</p>
      <p>Where we transfer personal information from a non-EEA country to another country, you acknowledge that third parties in other jurisdictions may not be subject to similar data protection laws to the ones in our jurisdiction. There are risks if any such third party engages in any act or practice that would contravene the data privacy laws in our jurisdiction and this might mean that you will not be able to seek redress under our jurisdiction’s privacy laws.</p>
      <h3>6. Your rights and controlling your personal information</h3>
      <p><strong>Choice and consent:</strong> By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this privacy policy. If you are under 16 years of age, you must have, and warrant to the extent permitted by law to us, that you have your parent or legal guardian’s permission to access and use the website and they (your parents or guardian) have consented to you providing us with your personal information. You do not have to provide personal information to us, however, if you do not, it may affect your use of this website or the products and/or services offered on or through it.</p>
      <p><strong>Information from third parties:</strong> If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.</p>
      <p><strong>Restrict:</strong> You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below. If you ask us to restrict or limit how we process your personal information, we will let you know how the restriction affects your use of our website or products and services.</p>
      <p><strong>Access and data portability:</strong> You may request details of the personal information that we hold about you. You may request a copy of the personal information we hold about you. Where possible, we will provide this information in CSV format or other easily readable machine format. You may request that we erase the personal information we hold about you at any time. You may also request that we transfer this personal information to another third party.</p>
      <p><strong>Correction:</strong> If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.</p>
      <p><strong>Notification of data breaches:</strong> We will comply laws applicable to us in respect of any data breach.</p>
      <p><strong>Complaints:</strong> If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.</p>
      <p><strong>Unsubscribe:</strong> To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.</p>
      <h3>7. Cookies</h3>
      <p>We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more information.</p>
      <h3>8. Business transfers</h3>
      <p>If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may continue to use your personal information according to this policy.</p>
      <h3>9. Limits of our policy</h3>
      <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</p>
      <h3>10. Changes to this policy</h3>
      <p>At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information. </p>
      <p>If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.</p>
      <p><strong>Maison Software Limited Data Controller</strong><br />
      Maison App Policy<br />
      Jaz King</p>
      <p><strong>Maison App Data Protection Officer</strong><br />
      Jaz King<br />
      jaz@maison.app</p>
      <p>This policy is effective as of 4 December 2019.</p>

      <h1>Cookies Policy</h1>

      <p>Last updated: December 04, 2019</p>

      <p>Maison Software Limited ("us", "we", or "our") uses cookies on the Maison website and the Maison mobile application (the "Service"). By using the Service, you consent to the use of cookies.</p>

      <p>Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.</p>

      <h2>What are cookies</h2>

      <p>Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.</p>

      <p>Cookies can be "persistent" or "session" cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.</p>

      <h2>How Maison Limited uses cookies</h2>

      <p>When you use and access the Service, we may place a number of cookies files in your web browser.</p>

      <p>We use cookies for the following purposes:</p>

      <ul>
      <li>
          <p>To enable certain functions of the Service</p>
      </li>
      <li>
          <p>To provide analytics</p>
      </li>
      <li>
          <p>To store your preferences</p>
      </li>
      <li>
          <p>To enable advertisements delivery, including behavioral advertising</p>
      </li>
      </ul>

      <p>We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:</p>

      <ul>
      <li>
          <p>Essential cookies. We may use cookies to remember information that changes the way the Service behaves or looks, such as a user's language preference on the Service.</p>
      </li>
      <li>
          <p>Accounts-related cookies. We may use accounts-related cookies to authenticate users and prevent fraudulent use of user accounts. We may use these cookies to remember information that changes the way the Service behaves or looks, such as the "remember me" functionality.</p>
      </li>
      <li>
          <p>Analytics cookies. We may use analytics cookies to track information how the Service is used so that we can make improvements. We may also use analytics cookies to test new advertisements, pages, features or new functionality of the Service to see how our users react to them.</p>
      </li>
      <li>
          <p>Advertising cookies. These type of cookies are used to deliver advertisements on and through the Service and track the performance of these advertisements. These cookies may also be used to enable third-party advertising networks to deliver ads that may be relevant to you based upon your activities or interests.</p>
      </li>
      </ul>

      <h2>Third-party cookies</h2>

      <p>In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.</p>

      <h2>What are your choices regarding cookies</h2>

      <p>If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.</p>

      <p>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>

      <ul>
      <li>
          <p>For the Chrome web browser, please visit this page from Google: <a href="https://support.google.com/accounts/answer/32050">https://support.google.com/accounts/answer/32050</a></p>
      </li>
      <li>
          <p>For the Internet Explorer web browser, please visit this page from Microsoft: <a href="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</a></p>
      </li>
      <li>
          <p>For the Firefox web browser, please visit this page from Mozilla: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></p>
      </li>
      <li>
          <p>For the Safari web browser, please visit this page from Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></p>
      </li>
      <li>
          <p>For any other web browser, please visit your web browser's official web pages.</p>
      </li>
      </ul>

      <h2>Where can you find more information about cookies</h2>

      <p>You can learn more about cookies and the following third-party websites:</p>

      <ul>
      <li>
          <a href="http://www.allaboutcookies.org/"><p>All About Cookies</p></a>
      </li>
      <li>
          <a href="http://www.networkadvertising.org/"><p>Network Advertising Initiative</p>/</a>
      </li>
      </ul>
      </div>
      <Footer/>
    </div>
  );
}

export default Policy;
