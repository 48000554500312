import React from 'react';
import '../layout/App.css';
import '../layout/legal.css';
import Navbar from "../components/homepage/navbar";
import Footer from "../components/homepage/footer.js";
import ErrorImage from '../images/illustrations/404-image.js';

function Policy() {
  return (
    <div>
        <div className="Legal-Content-Wrapper">
          <h1>Nothing to see here...</h1>
          <ErrorImage fill="#49c" width={100} name="phone" />
        </div>
      <Footer/>
    </div>
  );
}

export default Policy;
