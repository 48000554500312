import React from 'react'
import '../../layout/footer.css';
import logo from "../../images/longlogo-1.svg";
import {Link} from "react-router-dom";

import Policy from '../../pages/Policy';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-hero">
        <div className="footer-hero-column">
          <Link to="/">
            <img
            src={logo}
            className="footer-logo"
            alt="Logo"
            width="200"
          />
          </Link>
          <p className="footer-copyright">© Copyright 2024 Maison Software Limited. <br/> All rights reserved.</p>
        </div>
        <div className="footer-hero-column">
          <h6>Company</h6>
          <Link to="/"><p>Home</p></Link>
          <a target="_blank" href="https://angel.co/company/maison"><p>Jobs</p></a>
          <a target="_blank" href="https://medium.com/@maisonapp"><p>Blog</p></a>
          <a target="_blank" href="https://climate.stripe.com/NcwLLy"><p>Climate</p></a>
        </div>
        <div className="footer-hero-column">
          <h6>Legal</h6>
          <Link to="/Policy"><p>Privacy Policy</p></Link>
          <Link to="/Terms"><p>Terms</p></Link>
        </div>
        <div className="footer-hero-column">
          <h6>Follow</h6>
          <div className="footer-social">
            <a target="_blank" href="https://www.instagram.com/maison.app"><div className="footer-social-instagram"></div></a>
            <a target="_blank" href="https://www.twitter.com/maison_app"><div className="footer-social-twitter"></div></a>
            <a target="_blank" href="https://angel.co/company/maisonapp"><div className="footer-social-angelist"></div></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
