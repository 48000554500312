import React, { Component } from 'react';
// import the component
import Mailchimp from 'react-mailchimp-form'
import '../../layout/forms.css';
import {Link} from "react-router-dom";


class Subscribe extends Component {
  render() {
    return (
      <div className="form-wrapper">
        <Mailchimp
        action='https://xyz.us5.list-manage.com/subscribe/post?u=88dedb9555b6fba1ba8e6957e&amp;id=8ec7eaaed5'

        messages = {
            {
              sending: " ",
              success: "Thank you for subscribing, see you soon!",
              error: "An unexpected internal error has occurred.",
              empty: " ",
              duplicate: "You have already used this email",
              button: "Sign up"
            }
          }

        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
        ]}
        />
        <Link to="/Policy"><p>Learn about our Privacy Policy.</p></Link>
        </div>

    );
  }
}

export default Subscribe;
