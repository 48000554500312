import React, { Component } from "react";
import '../../layout/navbar.css';
import { Link, animateScroll as scroll } from "react-scroll";
import logo from "../../images/logo.svg";

export default class Navbar extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false
    }
  }

  handleScroll = (event) => {
  const scrollTop = window.pageYOffset

  if (scrollTop > 20) {
    this.setState({ hasScrolled: true })
  } else {
    this.setState({ hasScrolled: false })
  }
  }

  componentDidMount() {
      window.addEventListener('scroll', this.handleScroll)
  }
  render() {
    return (
          <div className={this.state.hasScrolled ? 'Navbar NavbarScrolled' : 'Navbar'}>
      <div className={this.state.hasScrolled ? 'navBarItems ToolbarScrolled' : 'navBarItems'}>
              <h1 className="nav-title" onClick={this.scrollToTop}> 
              houseshare
              </h1>
              <Link
                activeClass="active"
                to="passsection"
                spy={true}
                smooth={true}
                offset={-180}
                duration={500} >
              <p>RentPass</p>
            </Link>
            <Link
              activeClass="active"
              to="property"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500} >
            <p>Property</p>
            </Link>
            <Link
              activeClass="active"
              to="market"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500} >
            <p>Marketplace</p>
            </Link>
            <Link
              activeClass="active"
              to="copilot"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500} >
            <p>CoPilot</p>
            </Link>
            {/* <a target="_blank" href="https://medium.com/maison-magazine">
            <p>Blog</p>
            </a> */}
            <p></p>
            <a target="_blank" href="https://apps.apple.com/app/apple-store/id1551274067?pt=121810924&ct=homepage&mt=8">
            <div className="navbar-SignUp">
              <p className="buttonText">Download</p>
            </div>
            </a>
        </div>
    </div>
  );
}
}
