import React from 'react';
import HeroSection from '../components/homepage/section-top.js'
import BodySection from '../components/homepage/section-property.js'
import ComingSoon from '../components/homepage/comingsoon.js'
import Navbar from "../components/homepage/navbar";
import Footer from "../components/homepage/footer.js";
import CookieConsent from "react-cookie-consent";
import {Link} from "react-router-dom";

function App() {
  return (
    <div className="Content-Wrapper">

      <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="aisonCookie"
      style={{ background: "#ffffff" }}
      buttonStyle={{ color: "#18AFD1", fontSize: "14px" }}
      expires={150}
      debug={false}
      style={{ background: "White" }}
      acceptOnScroll={true}
      acceptOnScrollPercentage={20}
      buttonStyle={{background:"#18AFD1", color: "white", fontSize: "16px", borderRadius: "12px", fontFamily:"neue-haas-grotesk-display", height: "24px"}}
      >

      <Link to="/Policy">By browsing this website you agree to our Cookie Policy. Learn More.</Link>

      </CookieConsent>

      <Navbar/>
      <HeroSection/>
      <BodySection/> 
      <ComingSoon/>
      <Footer/>
    </div>
  );
}

export default App;
