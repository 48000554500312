import React from 'react';
import '../layout/App.css';

import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import ScrollToTopRoute from '../components/homepage/ScrollToTopRoute';


import Home from "./Home";
import Policy from "./Policy";
import Terms from "./Terms";
import NotFound from "./404";

function App() {
  return (
    <div className="App">
    <Router>
      <Switch>
        <ScrollToTopRoute exact path='/' component={Home} />
        <ScrollToTopRoute path='/Policy' component={Policy} />
        <ScrollToTopRoute path='/Terms' component={Terms} />
        <ScrollToTopRoute path='/404' component={NotFound} />
        <ScrollToTopRoute path='*' component={NotFound} />
      </Switch>
    </Router>
    </div>
  );
}

export default App;
