import React from 'react'
import '../../layout/homepage.css';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import pay from "../../images/building.columns.svg";
import split from "../../images/arrow.triangle.branch.svg";
import log from "../../images/wrench.and.screwdriver.svg";
import chat from "../../images/bubble.left.and.bubble.right.svg";
import doc from "../../images/doc.viewfinder@4x.png";


import search from "../../images/location.magnifyingglass.svg";
import vision from "../../images/visionpro.svg";

import offer from "../../images/person.crop.square.filled.and.at.rectangle.svg";
import deposit from "../../images/creditcard.svg";
import movein from "../../images/key.horizontal.svg";


import verify from "../../images/checkmark.shield.svg";
import organise from "../../images/folder.svg";
import share from "../../images/arrow.up.and.person.rectangle.portrait.svg";
import rentpass from "../../images/person.badge.shield.checkmark.svg";


const PageOne = () => {
  return (
    <div className="section-wrapper">

   <div className="renterspass-hero" id="passsection">
   <Fade left cascade>
        <div className="renterspass-heading">
          <h2>
          Your renting <br/>profile
          </h2>

          <div className='feature-list'>
            <div className='feature-list-item'>
                <img
                src={verify}
                className="feature-list-icon"
                alt="Logo"
                width="20"
              />
              <p>Verify your identity</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={organise}
                className="feature-list-icon"
                alt="Logo"
                width="20"
              />
              <p>Organise your information</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={share}
                className="feature-list-icon"
                alt="Logo"
                width="20"
              />
              <p>Share your RentPass via a url via PassLink</p>
            </div>
          </div>
        </div>
        </Fade>
        <Zoom>
        <div className="renterspass-hero-img"></div>
        </Zoom>
      </div>

      <div className="review-card">
        <div className="review-card-wrapper">
          <h2>"Houseshare allows us to manage all our properties in a more efficient and modern way. Our tenants love the design and ease of use.”</h2>
            <div className='ravicard'>
              <div className='raviimage'></div>
                <h6>Durham Living</h6>
                <p>Co-living Developer</p>
            </div>
        </div>
      </div>

     <div className="renterspass-hero" id="property">
      <Zoom>
      <div className="property-feature-img"></div>
     </Zoom>
      <Fade left cascade>
      <div className="renterspass-heading">
          <h2>Stay on top of <br/>your home</h2>

          <div className='feature-list'>
            <div className='feature-list-item'>
                <img
                src={pay}
                className="feature-list-icon"
                alt="pay"
                width="20"
              />
              <p>Send, request and schedule payments</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={split}
                className="feature-list-icon"
                alt="pay"
                width="20"
              />
              <p>Split payments and set budgets with the house</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={log}
                className="feature-list-icon"
                alt="pay"
                width="20"
              />
              <p>Log and track maintance requests</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={chat}
                className="feature-list-icon"
                alt="pay"
                width="20"
              />
              <p>Message the house to post updates</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={doc}
                className="feature-list-icon"
                alt="pay"
                width="20"
              />
              <p>Upload documents, tenancies and more</p>
            </div>
          </div>
        </div>
        </Fade>
        <Zoom>
           <div className="property-feature-img-mobile"></div>
           
        </Zoom>
      </div> 

      <div className="review-card">
        <div className="review-card-wrapper">
            <div className='ravicard'>
              <div className='jessimage'></div>
                <h6>Jess</h6>
                <p>Tenant</p>
            </div>
          <h2>"Houseshare made it easy for me and my flatmates to manage our own flatshare via a single app. Renters pass helped us keep our details in one place to share.”</h2>
        </div>
      </div>

      <div className="renterspass-hero" id="market">
      <Fade left cascade>
        <div className="renterspass-heading">
          <h2>
          Discover and market <br/>your home
          </h2>
          <div className='feature-list'>
            <div className='feature-list-item'>
                <img
                src={search}
                className="feature-list-icon"
                alt="Logo"
                width="20"
              />
              <p>Find and list properties</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={vision}
                className="feature-list-icon"
                alt="Logo"
                width="20"
              />
              <p>Goodbye bookings and hello AR viewings</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={offer}
                className="feature-list-icon"
                alt="Logo"
                width="20"
              />
              <p>Send offers using your Rent Pass</p>
            </div>
            <div className='feature-list-item'>
                <img
                src={movein}
                className="feature-list-icon"
                alt="Logo"
                width="20"
              />
              <p>Move in and manage your home</p>
            </div>
          </div>
        </div>
        </Fade>
        <Zoom>
       <div className="marketplace-img"></div>
        </Zoom>
      </div>

    </div>
    
  )
}

export default PageOne
